import React, { FC } from 'react'
import { ThemeProvider } from 'styled-components'

import theme, { GlobalStyle } from 'src/theme'
import { Flex } from 'src/components'
import Background from './Background'
import Header from './Header'

const Layout: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />

    <Header />

    <Background />

    <Flex height="calc(100vh - 80px)" overflowY="auto" justifyContent="center">
      {children}
    </Flex>
  </ThemeProvider>
)

export default Layout
