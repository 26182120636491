import React, { FC, DetailedHTMLProps } from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface SeoProps {
  description?: string
  lang?: string
  meta?: DetailedHTMLProps<any, any>[]
  title?: string
}

const Seo: FC<SeoProps> = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
}) => {
  const { wordpress } = useStaticQuery(
    graphql`
      query {
        wordpress {
          generalSettings {
            title
            description
          }
        }
      }
    `
  )

  const metaTitle = title
    ? `${title} | ${wordpress.generalSettings.title}`
    : wordpress.generalSettings.title
  const metaDescription = description || wordpress.generalSettings.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:title`,
        //   content: title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: metaDescription,
        // },
      ].concat(meta)}
    />
  )
}

export default Seo
