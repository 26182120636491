import React, { FC } from 'react'
import styled from 'styled-components'
import { FieldRenderProps } from 'react-final-form'

import { Box } from 'src/components'

const StyledInput = styled(Box)`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fontSizes.m}px;

  &::placeholder {
    color: ${props => props.theme.colors.placeholder};
  }

  resize: none;

  &:read-only {
    background-color: ${props => props.theme.colors.background};
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
    outline: none;
    box-shadow: none;
  }
`

type InputProps = FieldRenderProps<string, any>
const Input: FC<InputProps> = ({ meta, input, ...rest }) => {
  return (
    <StyledInput
      as="input"
      border="solid"
      borderRadius="m"
      borderWidth="2px"
      px="17px"
      py="12px"
      autoComplete="off"
      {...input}
      {...rest}
    />
  )
}

export default Input
