import React, { FC } from 'react'

import { Flex, Box } from 'src/components'
import Logo from 'src/images/hitchhero-logo.svg'

const LayoutHeader: FC = () => (
  <Box height="80px" pl="28px" borderBottom="solid" bg="background">
    <Flex height="100%" alignItems="center">
      <Logo height="48px" />
    </Flex>
  </Box>
)

export default LayoutHeader
