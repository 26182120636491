import styled from 'styled-components'
import { typography, TypographyProps } from 'styled-system'

const Text = styled.span<TypographyProps>`
  ${typography}
`

Text.defaultProps = {
  color: 'text',
  fontFamily: 'primary',
  fontSize: 'm',
}

export default Text
