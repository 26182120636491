import { createGlobalStyle } from 'styled-components'
import 'typeface-source-sans-pro'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
  hideProgressBar: true,
  toastClassName: 'toast',
  className: 'toast-container',
  bodyClassName: 'toast-body',
})

const theme = {
  colors: {
    text: '#000',
    border: '#e0e2ee',
    background: '#fff',
    input: '#e3e7ed',
    primary: '#3f40f0',
    placeholder: '#aab1c2',
  },
  fontSizes: {
    m: 16,
    l: 24,
    lMob: 18,
    xl: 48,
    xlMob: 28,
  },
  fonts: { primary: 'Source Sans Pro', secondary: 'Work Sans' },
  radii: {
    m: 6,
    l: 10,
  },
  shadows: {
    l: '0 4px 13px 0 rgba(47, 61, 156, 0.06)',
  },
}

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.primary};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px #f2f0fd inset !important;
  }

  .toast {
    background-color: ${theme.colors.background};
    padding: 14px 16px;
    border: 1px solid ${theme.colors.border};
    border-radius: ${theme.radii.m}px;
    box-shadow: ${theme.shadows.l};

    &-container {
      padding: 0;
      top: 110px;
      right: 30px;
    }

    &-body {
      font-family: ${theme.fonts.primary}, sans-serif;
      font-size: 15px;
      font-weight: normal;
      color: ${theme.colors.text};
    }
  }
`

export default theme
