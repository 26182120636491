import { ReactText } from 'react'
import styled from 'styled-components'
import { compose, variant, system } from 'styled-system'

type ButtonProps = {
  variant?: string
  width?: ReactText | ReactText[]
}

const Button = styled.button<ButtonProps>`
  appearance: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.1;
  }

  ${compose(
    variant({
      variants: {
        primary: {
          px: 24,
          py: 14,
          bg: 'primary',
          color: 'background',
          border: 'none',
          borderRadius: 'm',
          fontFamily: 'primary',
          fontSize: 18,
          fontWeight: 600,
          lineHeight: 1.15,
        },
      },
    }),
    system({
      width: true,
    })
  )}
`

Button.defaultProps = {
  variant: 'primary',
}

export default Button
