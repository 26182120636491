import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Box } from 'src/components'

const LayoutBackground: FC = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg.png" }) {
        childImageSharp {
          fixed(width: 194) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Box
      zIndex={-1}
      position="absolute"
      top="80px"
      left="0"
      right="0"
      bottom="0"
      backgroundImage={`url(${backgroundImage.childImageSharp.fixed.src})`}
      bg="background"
      backgroundPosition="top"
    />
  )
}

export default LayoutBackground
