import styled from 'styled-components'
import {
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  position,
  PositionProps,
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  background,
  BackgroundProps,
  color,
  ColorProps,
} from 'styled-system'

type BoxProps = LayoutProps &
  SpaceProps &
  PositionProps &
  BorderProps &
  BoxShadowProps &
  BackgroundProps &
  ColorProps

const Box = styled.div<BoxProps>`
  ${compose(layout, space, position, border, boxShadow, background, color)}
`

Box.defaultProps = {
  borderColor: 'border',
  borderWidth: '1px',
}

export default Box
