import styled from 'styled-components'
import { flexbox, FlexboxProps, layout, LayoutProps } from 'styled-system'

const Flex = styled.div<FlexboxProps & LayoutProps>`
  display: flex;

  ${flexbox}
  ${layout}
`

export default Flex
